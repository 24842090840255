import React, { useState } from "react"
import axios from "axios"

import { discordUrl, appstoreUrl } from "../constants"
import TwitchToTiktokMOV from "../images/twitch-to-tiktok.mp4"
import Head from "../components/Head"
import Footer from "../components/Footer"
import Loader from "../components/Loader"
import Seo from "../components/seo"

const DownloadPage = () => {
  const [clipUrl, setClipUrl] = useState("")
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState(null)

  const [showError, setShowError] = useState(false)

  const handleGetClip = (e) => {
    e.preventDefault()
    if (requestInProgress) return
    setRequestInProgress(true)
    setShowError(false)

    const params  = {
      clip_url: clipUrl,
    }

    axios.get("https://api.getstreamkit.com/public/get_clip_download?", { params })
      .then((res) => {
        const { thumbnail, mp4 } = res.data
        console.log(thumbnail, mp4)
        setThumbnailUrl(thumbnail)
        setDownloadUrl(mp4)
      })
      .catch((err) => setShowError(true))
      .finally(() => setRequestInProgress(false))
  }

  const errorContent = (
    <div className="tl pa3 mw6 br3 center mt4" style={{ background: "#FFFBEB", color: "#A35D30" }}>
      <div className="f6 b mb1" style={{  color: "#A35D30" }}>Could not get clip!</div>
      <div className="f6">Make sure your url is correctly formatted. For example:</div>
      <div className="f6">https://clips.twitch.tv/MushyEvilCiderFunRun-mUag4KhzsOXBfAqP</div>
    </div>
  )

  return (
    <div className="bg-primary">
      <Seo
        title="Share Kick clips and track your channel growth | StreamKit"
        description="StreamKit makes it easy to share Kick clips to TikTok and Instagram, track your channel's growth, and identify your biggest fans."
      />
      <div className="center">
        <section className="center pb2" style={{ maxWidth: "75rem" }}>
          <Head showBlog white />
          <div className="pa2 white tc" style={{ margin: "80px 0 82px" }}>
            <h1 className="mb3 mt0" style={{ fontSize: "3.5rem" }}>
              Download your <b className="kick-primary">Kick Clips</b>
            </h1>
            <div className="f3 mb3 lh-copy">
              Copy and paste any clip's url below and hit Get Download Link
            </div>
            {requestInProgress ? (
              <div style={{ height: "212px" }} className="flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <div className="pa4">
                {downloadUrl ? (
                  <>
                    <a href={downloadUrl}>
                      <img src={thumbnailUrl} alt="Clip Thumbnail" className="mb4" />
                    </a>
                    <div className="db mw6 center">
                      <a href={downloadUrl} className="kick-primary b f4 no-underline">
                        <div className="bg-white grow b ph4 pv3 br3">
                          Download Clip
                        </div>
                      </a>
                    </div>
                    <div
                      onClick={() => {
                        setDownloadUrl(null)
                        setThumbnailUrl(null)
                      }}
                      className="mt3 center dib pointer dim f5 underline white"
                    >
                      Download another →
                    </div>
                  </>
                ) : (
                  <form className="black-80" onSubmit={handleGetClip}>
                    <div className="center tc">
                      <input
                        className="input-reset ba b--black-20 pa3 f3 mb4 db w-100 br3 mw7 center"
                        type="text"
                        onChange={(e) => setClipUrl(e.target.value)}
                        placeholder="https://kick.com/gmhikaru?clip=31233"
                      />
                    </div>
                    <div onClick={handleGetClip} className="bg-white near-black b f4 br3 grow mw6 pointer ph4 pv3 center">
                      Get Download Link
                    </div>
                    {showError && errorContent}
                  </form>
                )}
              </div>
            )}
          </div>
          <div className="w-100 tc mt2">
            <a href="/?source=twitch-clip-download" className="white b">
              Powered by StreamKit - The #1 Twitch Clip Editing iOS app
            </a>
          </div>
        </section>
        <section className="pv5 ph3 w-100 relative bg-white" style={{ zIndex: 1 }}>
          <div className="flex flex-column items-center justify-center center" style={{ maxWidth: "75rem" }}>
            <div className="f3 f1-l tc mb3 b background-color" style={{maxWidth: "600px"}}>
              Edit your <b className="primary">Twitch Clips</b> in seconds and post directly to TikTok and Instagram
            </div>
            <div className="w-60-l w-100">
              <video playsInline autoPlay muted loop width="100%" height="auto">
                <source src={TwitchToTiktokMOV} type="video/mp4" />
              </video>
            </div>
            <div onClick={handleGetClip} className="bg-primary white b f3 br3 grow mw6 pointer ph4 pv3 center mt4">
              Download StreamKit for free
            </div>
          </div>
        </section>
        <section className="bg-white w-100 pv3 ph2 bg-primary center" style={{ maxWidth: "75rem" }}>
          <Footer white />
        </section>
      </div>
    </div>
  )
}

export default DownloadPage
